import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteaguaslindas.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '5200258',
  schema: 'pbi_dte_aguas_lindas',
  groupId: '87944428-8621-4e57-975c-39fa5c69b2df',
  analiseGeral: 'f3a73439-e240-489e-98d7-e06506b3fee3',
  analiseUsuarios: '33c0f496-ade9-40d9-990b-67f099aeb29a',
  analiseComunicados: 'c34fb977-8afa-4b8e-8d50-3feb37ba8bb3',
  analiseProcurador: 'eb01f66c-b96d-4048-8909-5ad34f720712',
  analiseEventos: '687d996d-b55e-4f5b-b48b-32aa1960a632',
  analiseContribuintes: '0e403ecb-377c-4430-a294-fa07670400aa'
};
